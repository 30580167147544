import { useEffect, useState } from "react";
import { useStaticQuery, graphql } from "gatsby";

const useSanitySettings = () => {
  const { sanitySettings } = useStaticQuery(
    graphql`
      query Settings {
        sanitySettings {
          footer {
            active
            footerText
            email
          }
        }
      }
    `
  );

  const [settings, setSettings] = useState(null);

  // ----------------------------------------------------------------------
  // lifecycle

  useEffect(() => {
    if (settings) {
      return;
    }

    setSettings(sanitySettings);
  }, [sanitySettings]);

  // ----------------------------------------------------------------------

  return { settings };
};

export default useSanitySettings;
