import React, { useState } from "react";
import typography from "~data/typography";
import cn from "classnames";
import { Grid } from "~components";
import * as styles from "./styles.module.scss";

/** ============================================================================
 * Slice
 */
const TypeTable = ({
  className,
  data: {
    backgroundColor,
    fontColor,
    padded,
    dockedTop,
    dockedBottom,
    previewText
  }
}) => {
  const getBreakpointString = (breakpointObject) =>
    `${breakpointObject.min}px-${
      breakpointObject.max === -1 ? `1920px+` : `${breakpointObject.max}px`
    }`;

  const [breakpoint, setBreakpoint] = useState(0);

  const getPreviewText = (styleId) =>
    previewText.find((text) => text.styleId === styleId)?.text;

  return (
    <section
      className={cn(className, styles.container, {
        [styles.dockedTop]: dockedTop,
        [styles.dockedBottom]: dockedBottom,
        [styles.padded]: padded
      })}
      style={{
        background: backgroundColor?.hex || `white`,
        color: fontColor?.hex || `white`
      }}
    >
      <Grid>
        <div className={cn(styles.toggleButtons, `caption`)}>
          {typography.map((breakpointObject, index) => (
            <button
              className={cn({
                [styles.activeOption]: breakpoint === index
              })}
              type="button"
              onClick={() => setBreakpoint(index)}
            >
              {getBreakpointString(breakpointObject)}
            </button>
          ))}
        </div>
      </Grid>

      <div className={cn(styles.labelRow)}>
        <Grid>
          <div className={cn(styles.specimenColumn, `tag`)}>Specimen</div>
          <div className={cn(styles.fontSizeColumn, `tag`)}>Font-size</div>
          <div className={cn(styles.lineHeightColumn, `tag`)}>Line-height</div>
          <div className={cn(styles.letterSpacingColumn, `tag`)}>
            Letter-spacing
          </div>
        </Grid>
      </div>

      {typography[breakpoint].styles.map(({ id: styleId, attributes }) => (
        <div className={styles.tableRow}>
          <Grid>
            <div className={cn(styles.specimenColumn, `tag`)}>{styleId}</div>
            <div className={styles.previewColumn} style={attributes}>
              {getPreviewText(styleId)}
            </div>
            <div className={cn(styles.fontSizeColumn, `tag`)}>
              {attributes.fontSize}
            </div>
            <div className={cn(styles.lineHeightColumn, `tag`)}>
              {attributes.lineHeight}
            </div>
            <div className={cn(styles.letterSpacingColumn, `tag`)}>
              {attributes.letterSpacing || `0em`}
            </div>
          </Grid>
        </div>
      ))}
    </section>
  );
};

export default TypeTable;
