import React from "react";
import { HTMLVideo, IntersectionAnimation } from "~components";

import * as styles from "./styles.module.scss";

const SimpleVideo = ({
  id,
  className,
  data: { backgroundColor, padded, dockedTop, dockedBottom, videoURL }
}) => {
  if (!videoURL || videoURL === ``) {
    return null;
  }

  return (
    <section
      sliceid={id}
      className={[
        className,
        styles.container,
        dockedTop ? styles.dockedTop : ``,
        dockedBottom ? styles.dockedBottom : ``,
        padded ? styles.padded : ``
      ].join(` `)}
      style={{ background: backgroundColor?.hex || `transparent` }}
    >
      <IntersectionAnimation>
        <div className={styles.grid}>
          <figure className={styles.figure}>
            <HTMLVideo
              className={styles.video}
              src={videoURL}
              autoPlay
              muted
              loop
              playsInline
            />
          </figure>
        </div>
      </IntersectionAnimation>
    </section>
  );
};

export default SimpleVideo;
