// extracted by mini-css-extract-plugin
export var container = "styles-module--container--07966";
export var desktop = "1340px";
export var dockedBottom = "styles-module--dockedBottom--7642c";
export var dockedTop = "styles-module--dockedTop--f888f";
export var giant = "2200px";
export var largeDesktop = "1600px";
export var largeMobile = "500px";
export var largeTablet = "1025px";
export var list = "styles-module--list--a534d";
export var listHeading = "styles-module--listHeading--9d1c0";
export var listHeadingHeight = "styles-module--listHeadingHeight--92978";
export var listHeadingSize = "styles-module--listHeadingSize--74dd3";
export var listHeadingSpacing = "styles-module--listHeadingSpacing--8b0b4";
export var listHeadingStyle = "styles-module--listHeadingStyle--0174d";
export var listItem = "styles-module--listItem--1b465";
export var listItemHeight = "styles-module--listItemHeight--14e52";
export var listItemSize = "styles-module--listItemSize--b96e3";
export var listItemSpacing = "styles-module--listItemSpacing--ca063";
export var listItemStyle = "styles-module--listItemStyle--772c4";
export var mobile = "400px";
export var padded = "styles-module--padded--5c8d3";
export var smallDesktop = "1260px";
export var smallMobile = "340px";
export var smallTablet = "660px";
export var tablet = "769px";