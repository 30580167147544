// extracted by mini-css-extract-plugin
export var appear = "styles-module--appear--440db";
export var appearDown = "styles-module--appear-down--77f31";
export var appearLeft = "styles-module--appear-left--e79c4";
export var appearRight = "styles-module--appear-right--c7b7b";
export var appearUp = "styles-module--appear-up--9aeb5";
export var container = "styles-module--container--1b6d4";
export var darkImage = "styles-module--darkImage--907ac";
export var desktop = "1340px";
export var giant = "2200px";
export var largeDesktop = "1600px";
export var largeMobile = "500px";
export var largeTablet = "1025px";
export var lightImage = "styles-module--lightImage--af02b";
export var mobile = "400px";
export var mobileImage = "styles-module--mobileImage--c1f76";
export var mobileImageContainer = "styles-module--mobileImageContainer--786c6";
export var mobileTextOverlay = "styles-module--mobileTextOverlay--9a61a";
export var smallDesktop = "1260px";
export var smallMobile = "340px";
export var smallTablet = "660px";
export var tablet = "769px";