import { GatsbyImage } from "gatsby-plugin-image";
import React from "react";
import COLORS from "~data/colors.json";
import { Grid, IntersectionAnimation } from "~components";
import cn from "classnames";
import { hexToRGB } from "~utils/helpers";

import * as styles from "./styles.module.scss";

/** ============================================================================
 * Slice
 */
const ColorGrid = ({
  className,
  data: {
    backgroundColor,
    textColor,
    backgroundImage,
    padded,
    dockedTop,
    dockedBottom,
    category,
    text
  }
}) => {
  const getFormattedCategoryText = () => {
    if (category === `mono`) {
      return `Mono`;
    }
    if (category === `digital/ux`) {
      return `Digital / UX`;
    }
    if (category === `brand`) {
      return `Brand`;
    }
    return ``;
  };

  return (
    <section
      className={[
        className,
        styles.container,
        dockedTop ? styles.dockedTop : ``,
        dockedBottom ? styles.dockedBottom : ``,
        padded ? styles.padded : ``
      ].join(` `)}
      style={{ background: backgroundColor?.hex || `white` }}
    >
      {backgroundImage && (
        <div className={styles.backgroundImageContainer}>
          <GatsbyImage
            style={{ width: `100%`, height: `100%` }}
            image={backgroundImage.asset.gatsbyImageData}
            alt=""
          />
          <div className={styles.backgroundImageContrastOverlay} />
        </div>
      )}

      <IntersectionAnimation>
        <div
          className={styles.content}
          style={{ color: textColor?.hex || `#ffffff` }}
        >
          <Grid noXPadding>
            <h3 className={cn(`h2`, styles.header)}>
              {getFormattedCategoryText()}
            </h3>

            <p className={cn(`b1`, styles.text)}>{text}</p>
          </Grid>

          <ul className={styles.grid}>
            {Object.keys(COLORS).map((colorKey) => {
              const color = COLORS[colorKey];
              const {
                id: title,
                category: colorCategory,
                hex,
                display
              } = color;

              if (colorCategory !== category) return null;

              const rgb = hexToRGB(hex);

              const fontColor = display === `dark` ? `white` : `black`;

              return (
                <li
                  key={`link-grid-${title}`}
                  className={styles.gridItem}
                  style={{ background: hex, color: fontColor }}
                >
                  <div className={styles.gridItemContent}>
                    <p className={cn(`b2`, styles.title)}>{title}</p>

                    <div>
                      <p className="b2">{hex}</p>
                      <p className="b2">{`R ${rgb?.r || `0`}  G ${
                        rgb?.g || `0`
                      }  B ${rgb?.g || `0`}`}</p>
                    </div>
                  </div>
                </li>
              );
            })}
          </ul>
        </div>
      </IntersectionAnimation>
    </section>
  );
};

export default ColorGrid;
