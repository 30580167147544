// extracted by mini-css-extract-plugin
export var backgroundImageContainer = "styles-module--backgroundImageContainer--92896";
export var backgroundImageContrastOverlay = "styles-module--backgroundImageContrastOverlay--ac837";
export var container = "styles-module--container--f5cca";
export var content = "styles-module--content--d923b";
export var desktop = "1340px";
export var dockedBottom = "styles-module--dockedBottom--42846";
export var dockedTop = "styles-module--dockedTop--e5f9d";
export var giant = "2200px";
export var grid = "styles-module--grid--9e9c8";
export var gridItem = "styles-module--gridItem--33ed8";
export var gridItemContent = "styles-module--gridItemContent--659f2";
export var header = "styles-module--header--0f202";
export var largeDesktop = "1600px";
export var largeMobile = "500px";
export var largeTablet = "1025px";
export var mobile = "400px";
export var padded = "styles-module--padded--4fc4f";
export var smallDesktop = "1260px";
export var smallMobile = "340px";
export var smallTablet = "660px";
export var tablet = "769px";
export var text = "styles-module--text--0fd38";