import { useEffect, useState } from "react";
import { useStaticQuery, graphql } from "gatsby";

const useSanityCustomPages = () => {
  // ---------------------------------------------------------------------------
  // context / ref / state

  const [customPages, setCustomPages] = useState(null);

  const { allSanityCustomPage } = useStaticQuery(
    graphql`
      query SanityCustomPages {
        allSanityCustomPage {
          edges {
            node {
              category
              title
              slug {
                current
              }

              #
              # sync this to slices available both locally and in lam-dbp-slices

              pagebuilder {
                slices {
                  ...HeroFragment
                  ...ColorGridFragment
                  ...ImageGridFragment
                  ...LinkGridFragment
                  ...SimpleImageFragment
                  ...SimpleVideoFragment
                  ...TextArticleFragment
                  ...TypeTableFragment
                }
              }
            }
          }
        }
      }
    `
  );

  // ---------------------------------------------------------------------------
  // methods

  const getCustomPageBySlug = (searchSlug) =>
    customPages?.[0]
      ? customPages.find(
          ({ slug }) =>
            slug.current === searchSlug ||
            `/${slug.current}` === searchSlug ||
            `${slug.current}/` === searchSlug ||
            `/${slug.current}/` === searchSlug
        )
      : null;

  const getEmptyCustomPages = () =>
    customPages?.filter((page) => !page?.pagebuilder?.slices?.[0]);

  const getEmptyCustomSlugs = () =>
    getEmptyCustomPages().map(({ slug }) => slug.current);

  // ---------------------------------------------------------------------------
  // lifecycle

  useEffect(() => {
    setCustomPages(allSanityCustomPage.edges.map(({ node }) => node));
  }, [allSanityCustomPage]);

  // ---------------------------------------------------------------------------

  return {
    customPages,
    getCustomPageBySlug,
    getEmptyCustomPages,
    getEmptyCustomSlugs
  };
};

export default useSanityCustomPages;
