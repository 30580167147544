import React from "react";
import { Grid, PageNavButton } from "~components";
import { useApp } from "~hooks";
import listBlocks from "~data/nav";
import * as styles from "./styles.module.scss";

const PageNavButtons = () => {
  const { pathname } = useApp();

  const cartegories = Object.keys(listBlocks);
  const pagesArray = [];

  cartegories.forEach((category) => pagesArray.push(...listBlocks[category]));
  const activePages = pagesArray.filter((page) => page.active);

  const getPrevPage = () => {
    if (pathname === `/`) {
      return null;
    }

    const currentPageIndex = activePages.findIndex(
      (page) => page.link === pathname
    );
    return activePages[currentPageIndex - 1] || null;
  };

  const getNextPage = () => {
    if (pathname === `/`) {
      return activePages[0];
    }

    const currentPageIndex = activePages.findIndex(
      (page) => page.link === pathname
    );
    return activePages[currentPageIndex + 1] || null;
  };

  const prevPage = getPrevPage();
  const nextPage = getNextPage();

  return (
    <nav id="nav" className={styles.container}>
      <Grid>
        {prevPage && (
          <PageNavButton
            direction="prev"
            className={styles.prevButton}
            to={prevPage.link}
            text={prevPage.label}
          />
        )}
        {nextPage && (
          <PageNavButton
            direction="next"
            className={styles.nextButton}
            to={nextPage.link}
            text={nextPage.label}
          />
        )}
      </Grid>
    </nav>
  );
};

export default PageNavButtons;
