// extracted by mini-css-extract-plugin
export var appear = "styles-module--appear--0ff7f";
export var appearDown = "styles-module--appear-down--4209f";
export var appearLeft = "styles-module--appear-left--494ce";
export var appearRight = "styles-module--appear-right--6e6f0";
export var appearUp = "styles-module--appear-up--b30c3";
export var article = "styles-module--article--f5766";
export var background = "styles-module--background--10025";
export var container = "styles-module--container--d272a";
export var content = "styles-module--content--0365c";
export var contentCategory = "styles-module--contentCategory--f4a32";
export var contentSubtitle = "styles-module--contentSubtitle--fe6bb";
export var contentTitle = "styles-module--contentTitle--6cbda";
export var desktop = "1340px";
export var figure = "styles-module--figure--fdd07";
export var giant = "2200px";
export var image = "styles-module--image--df6e7";
export var largeDesktop = "1600px";
export var largeMobile = "500px";
export var largeTablet = "1025px";
export var mobile = "400px";
export var smallDesktop = "1260px";
export var smallMobile = "340px";
export var smallTablet = "660px";
export var tablet = "769px";