/* eslint-disable react/button-has-type */

import React from "react";
import { SVG } from "~components";
import cn from "classnames";
import { Link } from "gatsby";
import * as styles from "./styles.module.scss";

/** ============================================================================
 * @component
 * Generic global button component
 *
 * @typedef {('primary' | 'secondary' | 'text' | 'caption')} Variant
 * @typedef {('button' | 'reset' | 'submit')} ButtonType
 *
 * @param 	{node}  			children			 Button text content
 * @param 	{Variant}   	variant			 	 Style variant
 * @param 	{ButtonType}  buttonType		 Button 'type' attribute
 * @param 	{string}  		to 			 			 Gatsby link path, renders as anchor, only for internal links
 * @param 	{string}  		href 			 		 For external links
 * @param 	{string}  		iconLeft 			 Icon to render left of text
 * @param 	{string}  		iconRight   	 Icon to render right of text
 * @param 	{string}  	  strikethrough  Text to render in strikethrough style
 * @param 	{string}  	  color  				 Hex code to override color styles
 * @param 	{string}  	  className  		 For additional styling config
 * @param 	{boolean} 	  light  				 Renders with light theme
 * @param 	{boolean}   	disabled  		 Whether is disabled
 * @param 	{boolean} 	  fluid  		 		 Takes full width of container
 * @param 	{boolean} 	  loading  		 	 Show loading spinner
 * @param 	{function} 	  onClick  		 	 Click handler
 * @param 	{active} 	  	active  		 	 Displays hover state
 * @param 	{boolean} 	  openInNewTab   Whether links open in same tab
 * @return	{node}
 */

const Button = ({
  children,
  variant = `primary`,
  buttonType = `button`,
  to,
  href,
  iconLeft,
  iconRight,
  strikethrough,
  color,
  light,
  disabled,
  fluid,
  loading,
  onClick,
  className,
  openInNewTab = true,
  active
}) => {
  let BtnElement;
  if (to) BtnElement = Link;
  else if (href) BtnElement = `a`;
  else BtnElement = `button`;

  return (
    <BtnElement
      type={to ? `` : buttonType}
      href={href}
      target={openInNewTab ? `_blank` : `_self`}
      rel="noreferrer noopener"
      to={to}
      disabled={disabled}
      onClick={() => onClick && onClick()}
      tabIndex={disabled ? `-1` : 0}
      style={{ backgroundColor: color }}
      className={cn(styles.button, className, styles[variant], {
        [styles.light]: light,
        [styles.fluid]: fluid,
        [styles.loading]: loading,
        [styles.disabled]: disabled,
        [styles.active]: active
      })}
    >
      <div
        className={cn(styles.content, {
          [styles.justifyBetween]: iconLeft || iconRight
        })}
      >
        {iconLeft && (
          <SVG svg={iconLeft} className={cn(styles.icon, styles.left)} />
        )}
        <span className={variant === `caption` ? `caption` : `button-text`}>
          {children}
          {strikethrough && (
            <span className={styles.strikethrough}>{strikethrough}</span>
          )}
        </span>
        {iconRight && (
          <SVG svg={iconRight} className={cn(styles.icon, styles.right)} />
        )}
      </div>
      <div className={styles.loaderContainer}>
        <div className={styles.loader} />
      </div>
    </BtnElement>
  );
};

export default Button;
