import React from "react";
import { Image, IntersectionAnimation } from "~components";

import * as styles from "./styles.module.scss";

/** ============================================================================
 * Slice
 */
const SimpleImage = ({
  id,
  className,
  data: { backgroundColor, padded, dockedTop, dockedBottom, image }
}) => {
  if (!image) {
    return null;
  }

  return (
    <section
      sliceid={id}
      className={[
        className,
        styles.container,
        dockedTop ? styles.dockedTop : ``,
        dockedBottom ? styles.dockedBottom : ``,
        padded ? styles.padded : ``
      ].join(` `)}
      style={{ background: backgroundColor?.hex || `transparent` }}
    >
      <IntersectionAnimation>
        <figure className={styles.figure}>
          <Image className={styles.image} image={image} />
        </figure>
      </IntersectionAnimation>
    </section>
  );
};

export default SimpleImage;
