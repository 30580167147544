// extracted by mini-css-extract-plugin
export var categoryGroup = "styles-module--categoryGroup--904ed";
export var categoryTitle = "styles-module--categoryTitle--f2091";
export var container = "styles-module--container--236e7";
export var content = "styles-module--content--2ca91";
export var desktop = "1340px";
export var disabledLink = "styles-module--disabledLink--b6b9f";
export var giant = "2200px";
export var largeDesktop = "1600px";
export var largeMobile = "500px";
export var largeTablet = "1025px";
export var link = "styles-module--link--1c9d8";
export var links = "styles-module--links--3420d";
export var listItem = "styles-module--listItem--6c17f";
export var mobile = "400px";
export var smallDesktop = "1260px";
export var smallMobile = "340px";
export var smallTablet = "660px";
export var tablet = "769px";
export var viewTag = "styles-module--viewTag--7f401";