import React from "react";
import { Image, Link } from "~components";
import linkGridBackground from "~assets/images/link-grid-background.jpg";
import cn from "classnames";
import * as styles from "./styles.module.scss";

/** ============================================================================
 * Slice
 */
const LinkGrid = ({ id, className, data: { linkGridItems } }) => (
  <section sliceid={id} className={[className, styles.container].join(` `)}>
    <figure className={[styles.background]}>
      <Image image={linkGridBackground} />
    </figure>

    <ul className={styles.grid}>
      {linkGridItems.map(({ disabled, link, heading, subheading }) => (
        <li
          key={`link-grid-${heading}`}
          style={{
            color: `white`
          }}
          className={cn(styles.gridItem, styles.gridItemM, {
            [styles.gridItemDisabled]: disabled
          })}
        >
          <Link to={link.url}>
            <div className={styles.gridItemContent}>
              <header>
                {subheading && <h3 className="b2">{subheading}</h3>}

                <h2 className="b1">{heading}</h2>
              </header>

              <p className="button-text">{link.text}</p>
            </div>
          </Link>
        </li>
      ))}

      {/* <li
        className={cn(styles.gridItem, styles.gridItemM, styles.gridItemBlank)}
      >
        <div className={cn(styles.gridItemBackground)} />
      </li> */}
    </ul>
  </section>
);

export default LinkGrid;
