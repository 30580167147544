// extracted by mini-css-extract-plugin
export var container = "styles-module--container--cce7e";
export var contentLeft = "styles-module--contentLeft--e846a";
export var contentRight = "styles-module--contentRight--50d74";
export var desktop = "1340px";
export var downloadLink = "styles-module--downloadLink--3b958";
export var giant = "2200px";
export var largeDesktop = "1600px";
export var largeMobile = "500px";
export var largeTablet = "1025px";
export var mobile = "400px";
export var smallDesktop = "1260px";
export var smallMobile = "340px";
export var smallTablet = "660px";
export var tablet = "769px";
export var typeGrid = "styles-module--typeGrid--0d37a";