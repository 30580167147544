import React from "react";
import { IntersectionAnimation, TypeGrid } from "~components";

import * as styles from "./styles.module.scss";

/** ============================================================================
 * Slice
 */
const TypeTable = ({
  className,
  data: { backgroundColor, fontColor, padded, dockedTop, dockedBottom }
}) => (
  <section
    className={[
      className,
      styles.container,
      dockedTop ? styles.dockedTop : ``,
      dockedBottom ? styles.dockedBottom : ``,
      padded ? styles.padded : ``
    ].join(` `)}
    style={{
      background: backgroundColor?.hex || `white`,
      color: fontColor?.hex || `white`
    }}
  >
    <IntersectionAnimation>
      <TypeGrid />
    </IntersectionAnimation>
  </section>
);

export default TypeTable;
