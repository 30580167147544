import React from "react";
import cn from "classnames";

import * as styles from "./styles.module.scss";

/** ============================================================================
 * @component
 * @param  {boolean}    	active 					Whether the menu is active
 * @param  {function}    	setActive 			Function to set the active state
 * @return {node}
 */

const Hamburger = ({ active, setActive }) => (
  <button
    type="button"
    onClick={() => setActive((prev) => !prev)}
    className={cn(styles.container, { [styles.hamburgerActive]: active })}
  >
    <div
      className={cn(styles.hamburgerLine, {
        [styles.hamburgerLineActive]: active
      })}
    />
    <div
      className={cn(styles.hamburgerLine, {
        [styles.hamburgerLineActive]: active
      })}
    />
    <div
      className={cn(styles.hamburgerLine, {
        [styles.hamburgerLineActive]: active
      })}
    />
    <div
      className={cn(styles.hamburgerLine, {
        [styles.hamburgerLineActive]: active
      })}
    />
  </button>
);

export default Hamburger;
