import React, { useEffect, useRef } from "react";
import cn from "classnames";
import { SVG } from "~components";
import { Link } from "gatsby";
import * as styles from "./styles.module.scss";

const PageNavButton = ({ className, to, text, direction }) => {
  const buttonRef = useRef(null);

  const handleKeyDown = (e) => {
    if (e.key === `ArrowLeft` && direction === `prev`) {
      buttonRef.current?.click();
    } else if (e.key === `ArrowRight` && direction === `next`) {
      buttonRef.current?.click();
    }
  };

  useEffect(() => {
    window.addEventListener(`keydown`, handleKeyDown);

    return () => {
      window.removeEventListener(`keydown`, handleKeyDown);
    };
  }, []);

  return (
    <Link ref={buttonRef} className={cn(className, styles.button)} to={to}>
      <div className={styles.content}>
        {direction === `prev` && (
          <SVG svg="arrowLeft" className={styles.icon} />
        )}
        <span className={styles.text}>{text}</span>
        {direction === `next` && (
          <SVG svg="arrowRight" className={styles.icon} />
        )}
      </div>
    </Link>
  );
};

export default PageNavButton;
