import React, { useEffect, useRef, useState } from "react";
import gsap from "gsap";
import { useInView } from "react-intersection-observer";
import cn from "classnames";
import { useWindowDimensions } from "~hooks";
import { Image, RippleImage } from "~components";

import * as styles from "./styles.module.scss";

/** ============================================================================
 * @component
 */
const ALTRClipper = ({ data: { backgroundColour } }) => {
  // ---------------------------------------------------------------------------
  // hooks

  const { screen, isDesktop } = useWindowDimensions();

  // ---------------------------------------------------------------------------
  // context / ref / state

  const { ref, inView } = useInView();
  const containerRef = useRef();

  const [cursorTimeout, setCursorTimeout] = useState(null);
  const [pos, setPos] = useState({
    x: 0,
    y: 0
  });
  const [prevPos, setPrevPos] = useState({
    x: 0,
    y: 0
  });

  // ---------------------------------------------------------------------------
  // variables

  const CURSOR_SIZE = 300;

  // ---------------------------------------------------------------------------
  // methods

  const handleMouseMove = (e) => {
    if (!containerRef?.current || !isDesktop) {
      return;
    }

    const x = e.pageX - containerRef.current.offsetLeft;
    const y = e.pageY - containerRef.current.offsetTop;

    setPos({
      x,
      y
    });
  };

  // ---------------------------------------------------------------------------
  // lifecycle

  useEffect(() => {
    if (typeof window === `undefined` || !containerRef?.current || !isDesktop) {
      return () => {
        window.removeEventListener(`mousemove`, handleMouseMove, false);
      };
    }

    window.addEventListener(`mousemove`, handleMouseMove, false);

    return () => {
      window.removeEventListener(`mousemove`, handleMouseMove, false);
    };
  }, [containerRef, isDesktop]);

  useEffect(() => {
    if (!inView || !isDesktop) {
      return () => {};
    }

    if (cursorTimeout) {
      clearTimeout(cursorTimeout);
    }

    const transition = {
      duration: 2,
      ease: `expo.out`
    };

    let velocityX = pos.x - prevPos.x;
    let velocityY = pos.y - prevPos.y;

    if (velocityX < 0) {
      velocityX = -velocityX;
    }

    if (velocityY < 0) {
      velocityY = -velocityY;
    }

    let scale = (velocityX + velocityY) / 75;

    if (scale < 1) {
      scale = 1;
    } else if (scale > 2.5) {
      scale = 2.5;
    }

    gsap.to(`.altr-clipper-dark-image`, {
      ...transition,
      clipPath: `circle(${CURSOR_SIZE * scale}px at ${pos.x}px ${pos.y}px)`
    });

    setPrevPos(pos);

    //
    // ensure the cursor returns to its default size on idle

    return () => {
      setCursorTimeout(
        setTimeout(() => {
          gsap.to(`.altr-clipper-dark-image`, {
            ...transition,
            clipPath: `circle(${CURSOR_SIZE}px at ${pos.x}px ${pos.y}px)`
          });
        }, 100)
      );
    };
  }, [isDesktop, inView, pos]);

  // ---------------------------------------------------------------------------
  // render

  return (
    <div ref={ref}>
      {(isDesktop && (
        <section
          className={styles.container}
          ref={containerRef}
          role="presentation"
        >
          <div className={styles.lightImage}>
            <RippleImage imageUrl="/images/clipper/f40-light.jpeg" pos={pos} />
          </div>

          <div className={cn(styles.lightImage, `altr-clipper-dark-image`)}>
            <RippleImage imageUrl="/images/clipper/f40-dark.jpeg" pos={pos} />
          </div>
        </section>
      )) || (
        <section className={styles.container}>
          <figure className={styles.mobileImageContainer}>
            <Image
              className={styles.mobileImage}
              image="/images/clipper/f40-light.jpeg"
            />
          </figure>

          <div className={styles.mobileTextOverlay}>
            <h2 className="h2">
              ALTR
              <br />
              ToolKit™
            </h2>
          </div>
        </section>
      )}
    </div>
  );
};

export default ALTRClipper;
