import React from "react";
import { Grid, Image, IntersectionAnimation } from "~components";
import * as styles from "./styles.module.scss";

/** ============================================================================
 * Slice
 */
const ImageGrid = ({ data: { backgroundColor, images } }) => {
  if (!images?.[0]) {
    return null;
  }

  return (
    <section style={{ background: backgroundColor?.hex || `transparent` }}>
      <IntersectionAnimation>
        <Grid>
          {images.map((image) => (
            <figure key={image._key} className={styles.figure}>
              <Image className={styles.image} image={image} />
            </figure>
          ))}
        </Grid>
      </IntersectionAnimation>
    </section>
  );
};

export default ImageGrid;
