// extracted by mini-css-extract-plugin
export var container = "styles-module--container--4f38a";
export var desktop = "1340px";
export var dockedBottom = "styles-module--dockedBottom--613a6";
export var dockedTop = "styles-module--dockedTop--970ea";
export var figure = "styles-module--figure--9636a";
export var giant = "2200px";
export var grid = "styles-module--grid--13161";
export var image = "styles-module--image--c2c43";
export var largeDesktop = "1600px";
export var largeMobile = "500px";
export var largeTablet = "1025px";
export var mobile = "400px";
export var padded = "styles-module--padded--f61d4";
export var smallDesktop = "1260px";
export var smallMobile = "340px";
export var smallTablet = "660px";
export var tablet = "769px";
export var video = "styles-module--video--df395";