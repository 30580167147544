import React from "react";
import { Grid, IntersectionAnimation, UsageCard } from "~components";

import * as styles from "./styles.module.scss";

const UsageCards = ({ data: { backgroundColor, usageCards } }) => (
  <div style={{ background: backgroundColor?.hex || `var(--color-black)` }}>
    <IntersectionAnimation>
      <Grid>
        {usageCards.map((card, i) => (
          <UsageCard
            className={styles.usageCard}
            key={i}
            image={card.image}
            status={card.status}
            text={card.text}
          />
        ))}
      </Grid>
    </IntersectionAnimation>
  </div>
);

export default UsageCards;
