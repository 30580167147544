import { useEffect, useState } from "react";
import { useStaticQuery, graphql } from "gatsby";

const useSanityPages = () => {
  // ---------------------------------------------------------------------------
  // context / ref / state

  const [pages, setPages] = useState(null);

  const { allSanityPage } = useStaticQuery(
    graphql`
      query SanityPages {
        allSanityPage {
          edges {
            node {
              title
              slug {
                current
              }

              #
              # sync this to slices available both locally and in lam-dbp-slices

              pagebuilder {
                slices {
                  ...HeroFragment
                  ...ColorGridFragment
                  ...ImageGridFragment
                  ...LinkGridFragment
                  ...SimpleImageFragment
                  ...SimpleVideoFragment
                  ...TextArticleFragment
                  ...TypeTableFragment
                }
              }
            }
          }
        }
      }
    `
  );

  // ---------------------------------------------------------------------------
  // methods

  const getPageBySlug = (searchSlug) =>
    pages?.[0]
      ? pages.find(
          ({ slug }) =>
            slug.current === searchSlug ||
            `/${slug.current}` === searchSlug ||
            `${slug.current}/` === searchSlug ||
            `/${slug.current}/` === searchSlug
        )
      : null;

  const getEmptyPages = () =>
    pages?.filter((page) => !page?.pagebuilder?.slices?.[0]);

  const getEmptySlugs = () => getEmptyPages().map(({ slug }) => slug.current);

  // ---------------------------------------------------------------------------
  // lifecycle

  useEffect(() => {
    setPages(allSanityPage.edges.map(({ node }) => node));
  }, [allSanityPage]);

  // ---------------------------------------------------------------------------

  return {
    pages,
    getPageBySlug,
    getEmptyPages,
    getEmptySlugs
  };
};

export default useSanityPages;
