/* eslint-disable react/no-unstable-nested-components */
/* eslint-disable react/prop-types */

import React, { useState } from "react";
import BlockContent from "@sanity/block-content-to-react";
import { getGatsbyImageData } from "gatsby-source-sanity";
import { Go, Image } from "~components";
import cn from "classnames";
import * as styles from "./styles.module.scss";

/** ============================================================================
 * @component
 */
const PortableText = ({ className, blocks, serializer }) => {
  // ---------------------------------------------------------------------------
  // context / ref / state

  const parseStyle = (style) => (style === `normal` ? `b1` : style);

  const [serializers] = useState({
    types: {
      block: ({ children, node }) => (
        <p className={parseStyle(node?.style) || `b1`}>{children}</p>
      ),
      altImage: (props) => {
        const fluidProps = getGatsbyImageData(
          props.node.asset._ref,
          { maxWidth: 800 },
          {
            projectId: process.env.GATSBY_SANITY_PROJECT_ID,
            dataset: `production`
          }
        );
        return <Image image={fluidProps} alt={props.node.altText} />;
      },
      linkRowItem: ({ node }) => (
        <div className={styles.linkRowItem}>
          <span className="b2">{node.text}</span>
          <a
            className="caption"
            href={node.url}
            target="_blank"
            rel="noopener noreferrer"
          >
            {node.linkText}
          </a>
        </div>
      )
    },
    marks: {
      strong: ({ children }) => <strong>{children}</strong>,
      em: ({ children }) => <em>{children}</em>,
      sup: ({ children }) => <sup>{children}</sup>,
      sub: ({ children }) => <sub>{children}</sub>,
      link: ({ children, mark }) => (
        <Go to={mark.href} newTab>
          {children}
        </Go>
      )
    },
    list: ({ type, children, node }) => {
      if (type === `bullet`) {
        return (
          <ul
            className={cn(
              parseStyle(node?.style) || `b1`,
              styles.unorderedList
            )}
          >
            {children}
          </ul>
        );
      }
      return (
        <ol className={cn(parseStyle(node?.style) || `b1`, styles.orderedList)}>
          {children}
        </ol>
      );
    },
    listItem: ({ children, node }) => (
      <li className={parseStyle(node?.style) || `b1`}>{children}</li>
    )
  });

  // ---------------------------------------------------------------------------
  // render

  if (!blocks) {
    // console.error(`Portable Text: Blocks is undefined`);
    return null;
  }

  return (
    <div className={className}>
      <BlockContent
        blocks={blocks}
        className={styles.blockContent}
        serializers={serializer || serializers}
      />
    </div>
  );
};

export default PortableText;
