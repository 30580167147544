import React from "react";
import { Grid } from "~components";
import cn from "classnames";
import listBlocks from "~data/nav";
import { Link } from "gatsby";
import { StaticImage } from "gatsby-plugin-image";
import * as styles from "./styles.module.scss";

const Menulist = () => {
  const categories = Object.keys(listBlocks);
  const categoriesArray = [];

  categories.forEach((category) => {
    const categoryObject = {
      title: category,
      pages: listBlocks[category]
    };
    categoriesArray.push(categoryObject);
  });

  return (
    <nav className={styles.container}>
      <StaticImage
        style={{ position: `absolute`, inset: 0 }}
        src="../../assets/images/backgrounds/oracle-card-cluster-bg.jpg"
      />
      <div className={styles.content}>
        <Grid>
          {categoriesArray?.map((category, i) => (
            <div key={i} className={styles.categoryGroup}>
              <Grid noXPadding>
                <h4 className={cn(`tag`, styles.categoryTitle)}>
                  {category.title}
                </h4>

                <ul className={cn(styles.links, `h3`)}>
                  {category.pages.map((page, j) => {
                    const isValidPage = page.active;

                    return isValidPage ? (
                      <li key={j} className={styles.listItem}>
                        <Link className={styles.link} to={page.link}>
                          {page.label}
                          <span className={cn(styles.viewTag, `tag`)}>
                            View
                          </span>
                        </Link>
                      </li>
                    ) : (
                      <li className={cn(styles.listItem, styles.disabledLink)}>
                        {page.label}
                      </li>
                    );
                  })}
                </ul>
              </Grid>
            </div>
          ))}
        </Grid>
      </div>
    </nav>
  );
};

export default Menulist;
