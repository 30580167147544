import React from "react";
import cn from "classnames";
import { useSanitySettings } from "~hooks";
import { Grid, PageNavButtons } from "~components";
import * as styles from "./styles.module.scss";

const Footer = () => {
  const { settings } = useSanitySettings();

  if (!settings?.footer.active) return null;

  return (
    <div className={styles.container}>
      <PageNavButtons />

      <Grid className={styles.contentGrid}>
        <h5 className={cn(`caption`, styles.copyright)}>
          © Altr{` `}
          {new Date().getFullYear()}
        </h5>

        <div className={styles.text}>
          <p className="b1">{settings?.footer.footerText}</p>

          <a
            href={`mailto:${settings?.footer.email}`}
            className={cn(styles.link, `underline`)}
          >
            {settings?.footer.email}
          </a>
        </div>
      </Grid>
    </div>
  );
};

export default Footer;
