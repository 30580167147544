import React from "react";
import cn from "classnames";
import { Grid, IntersectionAnimation } from "~components";

import * as styles from "./styles.module.scss";

/** ============================================================================
 * Slice
 */
const Heading = ({
  id,
  data: { backgroundColor, fontColor, subheading, heading }
}) => (
  <section
    sliceid={id}
    className={cn(styles.container)}
    style={{
      background: backgroundColor?.hex || `transparent`,
      color: fontColor?.hex || `white`
    }}
  >
    <IntersectionAnimation>
      <Grid node="article">
        <div className={styles.content}>
          <h3 className={cn(styles.subheading, `caption`)}>{subheading}</h3>
          <p className={cn(styles.heading, `h2`)}>{heading}</p>
        </div>
      </Grid>
    </IntersectionAnimation>
  </section>
);

export default Heading;
