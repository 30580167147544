import React, { useEffect, useRef } from "react";
import gsap from "gsap";
import cn from "classnames";
import { useScroll, useWindowDimensions } from "~hooks";
import { Grid, Image, Button } from "~components";

import * as styles from "./styles.module.scss";

const Hero = ({
  id,
  data: { fontColor, image, imageXS, category, title, subtitle, downloadLink }
}) => {
  // ---------------------------------------------------------------------------
  // hooks

  const { scrollTop } = useScroll();
  const { screen } = useWindowDimensions();

  // ---------------------------------------------------------------------------
  // context / ref / state

  const mediaRef = useRef();
  const textRef = useRef();

  // ---------------------------------------------------------------------------
  // lifecycle

  useEffect(() => {
    const mediaElement = mediaRef.current;
    const textElement = textRef.current;

    const mediaScrollFactor = screen === `xs` ? 0.1 : 0.2;
    const textScrollFactor = screen === `xs` ? -0.05 : -0.1;

    gsap.to(mediaElement, {
      y: scrollTop * mediaScrollFactor,
      ease: `none`,
      duration: 0
    });

    gsap.to(textElement, {
      y: scrollTop * textScrollFactor,
      ease: `none`,
      duration: 0
    });
  }, [scrollTop]);

  // ---------------------------------------------------------------------------
  // render

  return (
    <section sliceid={id} className={styles.container}>
      <div className={styles.background}>
        <figure ref={mediaRef} className={styles.figure}>
          <Image className={styles.image} image={image} />
        </figure>
      </div>

      <article ref={textRef} className={styles.article}>
        <Grid>
          <div className={cn(styles.content, `frosted-glass-light`)}>
            <h3
              className={cn(styles.contentCategory, `caption`)}
              style={{ color: fontColor?.hex || `white` }}
            >
              {category}
            </h3>

            <h1
              className={cn(styles.contentTitle, `h1`)}
              style={{ color: fontColor?.hex || `white` }}
            >
              {title}
            </h1>

            <h3 className={cn(styles.contentSubtitle, `caption`)}>
              {subtitle}
            </h3>

            {downloadLink && (
              <Button
                href={downloadLink.url}
                fluid
                variant="secondary"
                light
                iconRight="download"
              >
                {downloadLink.text}
              </Button>
            )}
          </div>
        </Grid>
      </article>
    </section>
  );
};

export default Hero;
