// extracted by mini-css-extract-plugin
export var background = "styles-module--background--e8448";
export var container = "styles-module--container--44eee";
export var desktop = "1340px";
export var giant = "2200px";
export var grid = "styles-module--grid--65115";
export var gridItem = "styles-module--gridItem--af667";
export var gridItemBackground = "styles-module--gridItemBackground--5c9ee";
export var gridItemBlank = "styles-module--gridItemBlank--8a458";
export var gridItemContent = "styles-module--gridItemContent--a9f00";
export var gridItemDisabled = "styles-module--gridItemDisabled--a1152";
export var gridItemL = "styles-module--gridItemL--2a8ff";
export var gridItemM = "styles-module--gridItemM--20b0a";
export var gridItemS = "styles-module--gridItemS--4f272";
export var gridItemSubtitle = "styles-module--gridItemSubtitle--879f0";
export var largeDesktop = "1600px";
export var largeMobile = "500px";
export var largeTablet = "1025px";
export var mobile = "400px";
export var smallDesktop = "1260px";
export var smallMobile = "340px";
export var smallTablet = "660px";
export var tablet = "769px";