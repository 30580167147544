// extracted by mini-css-extract-plugin
export var appearDown = "styles-module--appear-down--decee";
export var category = "styles-module--category--56f76";
export var container = "styles-module--container--50795";
export var content = "styles-module--content--ac221";
export var desktop = "1340px";
export var giant = "2200px";
export var largeDesktop = "1600px";
export var largeMobile = "500px";
export var largeTablet = "1025px";
export var list = "styles-module--list--92ea5";
export var menu = "styles-module--menu--e422a";
export var menuContainer = "styles-module--menuContainer--76274";
export var mobile = "400px";
export var navbarCurrentPage = "styles-module--navbarCurrentPage--6a541";
export var navbarLeftColumn = "styles-module--navbarLeftColumn--c1d8c";
export var navbarListTitle = "styles-module--navbarListTitle--e1f49";
export var navbarLogo = "styles-module--navbarLogo--55a24";
export var navbarPartition = "styles-module--navbarPartition--32fb1";
export var smallDesktop = "1260px";
export var smallMobile = "340px";
export var smallTablet = "660px";
export var submenu = "styles-module--submenu--c52d5";
export var submenuItem = "styles-module--submenuItem--2b5fa";
export var submenuItemDisabled = "styles-module--submenuItemDisabled--22bc1";
export var submenuLabel = "styles-module--submenuLabel--20094";
export var tablet = "769px";