/* eslint-disable react/jsx-props-no-spreading */

import React from "react";

// Unique SVGs

// Icon library
import { ReactComponent as arrowUp } from "~assets/svg/arrow-up.svg";
import { ReactComponent as arrowRight } from "~assets/svg/arrow-right.svg";
import { ReactComponent as arrowBottom } from "~assets/svg/arrow-bottom.svg";
import { ReactComponent as arrowLeft } from "~assets/svg/arrow-left.svg";
import { ReactComponent as check } from "~assets/svg/check.svg";
import { ReactComponent as checkboxChecked } from "~assets/svg/checkbox-checked.svg";
import { ReactComponent as checkboxUnchecked } from "~assets/svg/checkbox-unchecked.svg";
import { ReactComponent as chevronDown } from "~assets/svg/chevron-down.svg";
import { ReactComponent as cross } from "~assets/svg/cross.svg";
import { ReactComponent as download } from "~assets/svg/download.svg";
import { ReactComponent as facebook } from "~assets/svg/facebook.svg";
import { ReactComponent as instagram } from "~assets/svg/instagram.svg";
import { ReactComponent as linkedin } from "~assets/svg/linkedin.svg";
import { ReactComponent as logo } from "~assets/svg/logo.svg";
import { ReactComponent as lookingGlass } from "~assets/svg/looking-glass.svg";
import { ReactComponent as minus } from "~assets/svg/minus.svg";
import { ReactComponent as play } from "~assets/svg/play.svg";
import { ReactComponent as playSolid } from "~assets/svg/play-solid.svg";
import { ReactComponent as plus } from "~assets/svg/plus.svg";
import { ReactComponent as repeat } from "~assets/svg/repeat.svg";
import { ReactComponent as shield } from "~assets/svg/shield.svg";
import { ReactComponent as twitter } from "~assets/svg/twitter.svg";
import { ReactComponent as user } from "~assets/svg/user.svg";
import { ReactComponent as wallet } from "~assets/svg/wallet-icon.svg";
import { ReactComponent as warningTriangle } from "~assets/svg/warning-triangle.svg";

const svgs = {
  // Unique
  logo,

  // Library
  arrowUp,
  arrowRight,
  arrowBottom,
  arrowLeft,
  check,
  checkboxChecked,
  checkboxUnchecked,
  chevronDown,
  cross,
  download,
  facebook,
  instagram,
  linkedin,
  lookingGlass,
  minus,
  play,
  playSolid,
  plus,
  repeat,
  shield,
  twitter,
  user,
  wallet,
  warningTriangle
  // ... all icons as required
};

/** ============================================================================
 * @component
 * @param {string}     svg     Name of desired SVG, must be imported first in this file
 * @return {node}
 */

const SVG = ({ svg, ...props }) => {
  if (!svgs[svg]) return null;

  const SVGElement = svgs[svg];

  return <SVGElement {...props} aria-hidden />;
};

export default SVG;
