import React from "react";
import cn from "classnames";
import * as styles from "./styles.module.scss";

const Grid = ({ children, className, noXPadding }) => (
  <div
    className={cn(styles.grid, className, { [styles.noXPadding]: noXPadding })}
  >
    {children}
  </div>
);
export default Grid;
