import React from "react";
import { IntersectionAnimation } from "~components";
import { GatsbyImage } from "gatsby-plugin-image";
import cn from "classnames";
import * as styles from "./styles.module.scss";

const Media = ({ data: { mediaItems } }) => {
  const numberOfColumns = () => {
    const numberOfItems = mediaItems.length;

    if (numberOfItems === 1) {
      return 1;
    }

    // If items is divisible by 2, but NOT by 3
    if (numberOfItems % 2 === 0 && numberOfItems % 3 !== 0) {
      return 2;
    }

    return 3;
  };

  return (
    <div className={styles.container}>
      <div
        className={cn(styles.grid, {
          [styles.oneColumnLayout]: numberOfColumns() === 1,
          [styles.twoColumnLayout]: numberOfColumns() === 2,
          [styles.threeColumnLayout]: numberOfColumns() === 3
        })}
      >
        {mediaItems.map((item, i) => (
          <IntersectionAnimation
            animation="fadeGrow"
            delay={i * 100}
            key={i}
            className={styles.mediaItemContainer}
          >
            {item._type === `altImage` && (
              <GatsbyImage
                style={{ width: `100%`, height: `100%` }}
                alt=""
                image={item.asset.gatsbyImageData}
              />
            )}

            {item._type === `videoFile` && (
              <video
                className={styles.video}
                src={item.file.asset.url}
                muted
                loop
                autoPlay
                playsInline
              />
            )}

            {item._type === `videoUrl` && (
              <video
                src={item.url}
                className={styles.video}
                muted
                loop
                autoPlay
                playsInline
              />
            )}
          </IntersectionAnimation>
        ))}
      </div>
    </div>
  );
};

export default Media;
