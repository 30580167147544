import React from "react";
import cn from "classnames";
import { Grid, IntersectionAnimation, PortableText } from "~components";

import * as styles from "./styles.module.scss";

/** ============================================================================
 * Slice
 */
const TextArticle = ({
  data: {
    backgroundColor,
    fontColor,
    _rawContentLeft,
    _rawContentRight,
    downloadLink
  }
}) => (
  <section
    className={styles.container}
    style={{
      background: backgroundColor?.hex || `black`,
      color: fontColor?.hex || `white`
    }}
  >
    <IntersectionAnimation>
      <Grid>
        <div className={styles.contentLeft}>
          <PortableText blocks={_rawContentLeft} />
        </div>
        <div className={styles.contentRight}>
          <PortableText blocks={_rawContentRight} />
        </div>
        {downloadLink && (
          <a
            target="_blank"
            rel="noopener noreferrer"
            className={cn(`underline`, styles.downloadLink)}
            href={downloadLink.url}
          >
            {downloadLink.text}
          </a>
        )}
      </Grid>
    </IntersectionAnimation>
  </section>
);

export default TextArticle;
