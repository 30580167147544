import React from "react";
import cn from "classnames";
import { GatsbyImage } from "gatsby-plugin-image";
import * as styles from "./styles.module.scss";

const UsageCard = ({ image, status, text, className }) => (
  <div className={cn(styles.container, className)}>
    <div className={styles.imageContainer}>
      <GatsbyImage
        image={image?.asset?.gatsbyImageData}
        alt={image?.asset?.altText || ``}
        style={{ position: `absolute`, inset: 0 }}
      />
    </div>
    <div className={styles.bottomContent}>
      <div
        className={cn(styles.statusDot, {
          [styles.correct]: status === `correct`
        })}
      />
      <p className="b2">{text}</p>
    </div>
  </div>
);

export default UsageCard;
