// extracted by mini-css-extract-plugin
export var activeOption = "styles-module--activeOption--16174";
export var container = "styles-module--container--aa501";
export var desktop = "1340px";
export var dockedBottom = "styles-module--dockedBottom--0413a";
export var dockedTop = "styles-module--dockedTop--3372e";
export var fontSizeColumn = "styles-module--fontSizeColumn--49346";
export var giant = "2200px";
export var labelRow = "styles-module--labelRow--2466b";
export var largeDesktop = "1600px";
export var largeMobile = "500px";
export var largeTablet = "1025px";
export var letterSpacingColumn = "styles-module--letterSpacingColumn--8c870";
export var lineHeightColumn = "styles-module--lineHeightColumn--e5a6d";
export var mobile = "400px";
export var padded = "styles-module--padded--28dba";
export var previewColumn = "styles-module--previewColumn--ba095";
export var smallDesktop = "1260px";
export var smallMobile = "340px";
export var smallTablet = "660px";
export var specimenColumn = "styles-module--specimenColumn--af8d0";
export var tableRow = "styles-module--tableRow--b74a4";
export var tablet = "769px";
export var toggleButtons = "styles-module--toggleButtons--c97fd";