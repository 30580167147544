import React, { useEffect, useRef, useState } from "react";
import { Link } from "gatsby";
import cn from "classnames";
import { Hamburger, SVG } from "~components";
import { useApp } from "~hooks";
import listBlocks from "~data/nav";

import * as styles from "./styles.module.scss";

const Navbar = () => {
  // ---------------------------------------------------------------------------
  // hooks

  const { pathname, navbarLocationText } = useApp();

  // ---------------------------------------------------------------------------
  // context / ref / state

  const contentRef = useRef();

  const [active, setActive] = useState(false);
  const [contentHeight, setContentHeight] = useState(60);

  const calculateContentHeight = () => {
    const height = contentRef.current.offsetHeight;
    setContentHeight(height);
  };

  useEffect(() => {
    calculateContentHeight();
    window.addEventListener(`resize`, calculateContentHeight);
    return () => window.removeEventListener(`resize`, calculateContentHeight);
  }, []);

  useEffect(() => {
    setActive(false);
  }, [pathname]);

  // ---------------------------------------------------------------------------
  // render

  return (
    <div className={cn(styles.container)}>
      <div className={cn(styles.content, `frosted-glass-light`)}>
        <header>
          <div className={cn(styles.navbarLeftColumn)}>
            <Hamburger active={active} setActive={setActive} />

            <div className={cn(styles.navbarCurrentPage, `caption`)}>
              <div>{navbarLocationText.top}</div>
              <div>{navbarLocationText.bottom}</div>
            </div>
          </div>

          <Link to="/">
            <SVG svg="logo" className={styles.navbarLogo} />
          </Link>
        </header>

        <div
          className={styles.menuContainer}
          style={{ height: `${active ? contentHeight : 0}px` }}
        >
          <nav
            ref={contentRef}
            className={styles.menu}
            style={{ opacity: active ? 1 : 0 }}
          >
            {Object.keys(listBlocks).map((key) => {
              const list = listBlocks[key];

              return (
                <div className={cn(styles.submenu)}>
                  <h4 className={cn(styles.submenuLabel, `caption`)}>{key}</h4>

                  <ul>
                    {list.map(({ active: itemActive, label, link }, index) => {
                      const className = itemActive
                        ? cn(styles.submenuItem, `b1`)
                        : cn(
                            styles.submenuItem,
                            styles.submenuItemDisabled,
                            `b1`
                          );

                      return (
                        <li className={className}>
                          <Link
                            to={link}
                            className="header-list-item"
                            style={{ transitionDelay: `${index * 0.1}ms` }}
                          >
                            <span>{label}</span>
                            <SVG svg="arrowUp" className={styles.navbarLogo} />
                          </Link>
                        </li>
                      );
                    })}
                  </ul>
                </div>
              );
            })}
          </nav>
        </div>
      </div>
    </div>
  );
};

export default Navbar;
