// extracted by mini-css-extract-plugin
export var container = "styles-module--container--7b681";
export var desktop = "1340px";
export var dockedBottom = "styles-module--dockedBottom--2c704";
export var dockedTop = "styles-module--dockedTop--7f029";
export var figure = "styles-module--figure--57945";
export var giant = "2200px";
export var image = "styles-module--image--1c9f2";
export var largeDesktop = "1600px";
export var largeMobile = "500px";
export var largeTablet = "1025px";
export var mobile = "400px";
export var padded = "styles-module--padded--2ae9a";
export var smallDesktop = "1260px";
export var smallMobile = "340px";
export var smallTablet = "660px";
export var tablet = "769px";