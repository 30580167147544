import React from "react";
import cn from "classnames";

import { ReactComponent as MadeWith } from "~assets/svg/toolkit-made-with.svg";
import { ReactComponent as Wordmark } from "~assets/svg/toolkit-wordmark-alt.svg";

import * as styles from "./styles.module.scss";

const ToolkitFooter = ({ dark = true }) => (
  <footer className={cn(styles.container, { [styles.light]: !dark })}>
    <div className={styles.grid}>
      <div className={styles.wordmark}>
        <a
          href="https://www.loveandmoney.agency/toolkit"
          rel="noopener noreferrer"
          target="_blank"
        >
          <Wordmark />
        </a>
      </div>

      <div className={cn(styles.links, { [styles.light]: !dark })}>
        <a
          href="https://www.loveandmoney.agency/"
          rel="noopener noreferrer"
          target="_blank"
        >
          <MadeWith />
        </a>
      </div>
    </div>
  </footer>
);

export default ToolkitFooter;
