// extracted by mini-css-extract-plugin
export var appear = "styles-module--appear--47aa9";
export var appearDown = "styles-module--appear-down--34922";
export var appearLeft = "styles-module--appear-left--bfa23";
export var appearRight = "styles-module--appear-right--699c2";
export var appearUp = "styles-module--appear-up--50655";
export var arrowLeft = "styles-module--arrowLeft--d4d11";
export var arrowRight = "styles-module--arrowRight--ce3ee";
export var container = "styles-module--container--4ab11";
export var navButton = "styles-module--navButton--50506";
export var navButtonAlone = "styles-module--navButtonAlone--880bb";
export var navButtonInner = "styles-module--navButtonInner--c2bd0";